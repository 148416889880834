import React from "react";
import {ServiceIconAssetProps} from "./ServiceIconAssetProps";
import {ColorTheme} from "../../../constants/ColorTheme";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/MediaQueries";

const MobileAppsByDesignSvg: React.FC<ServiceIconAssetProps> = ({ colorTheme }) => {
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP})

  return(
    isDesktop ? (
      colorTheme === ColorTheme.DARK ? (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="28.1257" y="13.5417" width="43.75" height="72.9167" rx="3.125" fill="#2F2F2F" stroke="#8A8A8A" strokeWidth="2.08333"/>
          <rect x="44.7926" y="17.7083" width="10.4167" height="2.08333" rx="1.04167" fill="black" stroke="#8A8A8A" strokeWidth="2.08333"/>
          <path d="M50.2129 53.442C50.2129 52.0327 50.7728 50.681 51.7693 49.6845C52.7659 48.6879 54.1175 48.128 55.5269 48.128C56.9363 48.128 58.2879 48.6879 59.2845 49.6845C60.281 50.681 60.8409 52.0327 60.8409 53.442C60.8409 54.8514 60.281 56.203 59.2845 57.1996C58.2879 58.1962 56.9363 58.7561 55.5269 58.7561C54.1175 58.7561 52.7659 58.1962 51.7693 57.1996C50.7728 56.203 50.2129 54.8514 50.2129 53.442V53.442Z" stroke="#8A8A8A" strokeWidth="2.04678"/>
          <path d="M39.584 64.0701C39.584 62.6607 40.1439 61.3091 41.1404 60.3125C42.137 59.3159 43.4886 58.756 44.898 58.756H50.212V64.0701C50.212 65.4794 49.6521 66.8311 48.6556 67.8276C47.659 68.8242 46.3074 69.3841 44.898 69.3841C43.4886 69.3841 42.137 68.8242 41.1404 67.8276C40.1439 66.8311 39.584 65.4794 39.584 64.0701Z" fill="#B1FFE3" stroke="#8A8A8A" strokeWidth="2.04678"/>
          <path d="M50.2129 37.5V48.128H55.5269C56.9363 48.128 58.2879 47.5682 59.2845 46.5716C60.281 45.575 60.8409 44.2234 60.8409 42.814C60.8409 41.4046 60.281 40.053 59.2845 39.0564C58.2879 38.0599 56.9363 37.5 55.5269 37.5H50.2129Z" fill="#C176E6" stroke="#8A8A8A" strokeWidth="2.04678"/>
          <path d="M39.584 42.814C39.584 44.2234 40.1439 45.575 41.1404 46.5716C42.137 47.5682 43.4886 48.128 44.898 48.128H50.212V37.5H44.898C43.4886 37.5 42.137 38.0599 41.1404 39.0564C40.1439 40.053 39.584 41.4046 39.584 42.814Z" fill="#F98B78" stroke="#8A8A8A" strokeWidth="2.04678"/>
          <path d="M39.584 53.442C39.584 54.8514 40.1439 56.203 41.1404 57.1996C42.137 58.1962 43.4886 58.7561 44.898 58.7561H50.212V48.128H44.898C43.4886 48.128 42.137 48.6879 41.1404 49.6845C40.1439 50.681 39.584 52.0327 39.584 53.442Z" fill="#EEC9FF" stroke="#8A8A8A" strokeWidth="2.04678"/>
        </svg>
      ) : (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="28.1252" y="13.5417" width="43.75" height="72.9167" rx="3.125" fill="#EFEFEF" stroke="black" strokeWidth="2.08333"/>
          <rect x="43.75" y="16.6667" width="12.5" height="4.16667" rx="2.08333" fill="black"/>
          <path d="M50.2114 53.442C50.2114 52.0327 50.7713 50.681 51.7679 49.6845C52.7644 48.6879 54.1161 48.128 55.5254 48.128C56.9348 48.128 58.2864 48.6879 59.283 49.6845C60.2796 50.681 60.8394 52.0327 60.8394 53.442C60.8394 54.8514 60.2796 56.203 59.283 57.1996C58.2864 58.1962 56.9348 58.756 55.5254 58.756C54.1161 58.756 52.7644 58.1962 51.7679 57.1996C50.7713 56.203 50.2114 54.8514 50.2114 53.442V53.442Z" stroke="#1E1E1E" strokeWidth="2.04678"/>
          <path d="M39.5835 64.0701C39.5835 62.6607 40.1434 61.3091 41.1399 60.3125C42.1365 59.3159 43.4881 58.756 44.8975 58.756H50.2115V64.0701C50.2115 65.4794 49.6516 66.8311 48.6551 67.8276C47.6585 68.8242 46.3069 69.3841 44.8975 69.3841C43.4881 69.3841 42.1365 68.8242 41.1399 67.8276C40.1434 66.8311 39.5835 65.4794 39.5835 64.0701Z" fill="#B1FFE3" stroke="#1E1E1E" strokeWidth="2.04678"/>
          <path d="M50.2114 37.5V48.128H55.5254C56.9348 48.128 58.2864 47.5682 59.283 46.5716C60.2796 45.575 60.8394 44.2234 60.8394 42.814C60.8394 41.4046 60.2796 40.053 59.283 39.0564C58.2864 38.0599 56.9348 37.5 55.5254 37.5H50.2114Z" fill="#C176E6" stroke="#1E1E1E" strokeWidth="2.04678"/>
          <path d="M39.5835 42.814C39.5835 44.2234 40.1434 45.575 41.1399 46.5716C42.1365 47.5682 43.4881 48.128 44.8975 48.128H50.2115V37.5H44.8975C43.4881 37.5 42.1365 38.0599 41.1399 39.0564C40.1434 40.053 39.5835 41.4046 39.5835 42.814Z" fill="#F98B78" stroke="#1E1E1E" strokeWidth="2.04678"/>
          <path d="M39.5835 53.442C39.5835 54.8514 40.1434 56.203 41.1399 57.1996C42.1365 58.1962 43.4881 58.756 44.8975 58.756H50.2115V48.128H44.8975C43.4881 48.128 42.1365 48.6879 41.1399 49.6845C40.1434 50.681 39.5835 52.0327 39.5835 53.442Z" fill="#EEC9FF" stroke="#1E1E1E" strokeWidth="2.04678"/>
        </svg>
      )
    ) : (
      colorTheme === ColorTheme.DARK ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="13.5" y="6.5" width="21" height="35" rx="1.5" fill="#2F2F2F" stroke="#8A8A8A"/>
          <rect x="21.5" y="8.5" width="5" height="1" rx="0.5" fill="black" stroke="#8A8A8A"/>
          <path d="M24.1014 25.6523C24.1014 24.9758 24.3702 24.327 24.8485 23.8487C25.3269 23.3703 25.9757 23.1016 26.6522 23.1016C27.3287 23.1016 27.9774 23.3703 28.4558 23.8487C28.9342 24.327 29.2029 24.9758 29.2029 25.6523C29.2029 26.3288 28.9342 26.9776 28.4558 27.4559C27.9774 27.9343 27.3287 28.203 26.6522 28.203C25.9757 28.203 25.3269 27.9343 24.8485 27.4559C24.3702 26.9776 24.1014 26.3288 24.1014 25.6523V25.6523Z" stroke="#8A8A8A" strokeWidth="0.982456"/>
          <path d="M19 30.7538C19 30.0774 19.2687 29.4286 19.7471 28.9502C20.2254 28.4719 20.8742 28.2031 21.5507 28.2031H24.1014V30.7538C24.1014 31.4303 23.8327 32.0791 23.3544 32.5575C22.876 33.0358 22.2272 33.3046 21.5507 33.3046C20.8742 33.3046 20.2254 33.0358 19.7471 32.5575C19.2687 32.0791 19 31.4303 19 30.7538Z" fill="#B1FFE3" stroke="#8A8A8A" strokeWidth="0.982456"/>
          <path d="M24.1014 18V23.1014H26.6522C27.3287 23.1014 27.9774 22.8327 28.4558 22.3544C28.9342 21.876 29.2029 21.2272 29.2029 20.5507C29.2029 19.8742 28.9342 19.2254 28.4558 18.7471C27.9774 18.2687 27.3287 18 26.6522 18H24.1014Z" fill="#C176E6" stroke="#8A8A8A" strokeWidth="0.982456"/>
          <path d="M19 20.5507C19 21.2272 19.2687 21.876 19.7471 22.3544C20.2254 22.8327 20.8742 23.1014 21.5507 23.1014H24.1014V18H21.5507C20.8742 18 20.2254 18.2687 19.7471 18.7471C19.2687 19.2254 19 19.8742 19 20.5507Z" fill="#F98B78" stroke="#8A8A8A" strokeWidth="0.982456"/>
          <path d="M19 25.6523C19 26.3288 19.2687 26.9776 19.7471 27.4559C20.2254 27.9343 20.8742 28.203 21.5507 28.203H24.1014V23.1016H21.5507C20.8742 23.1016 20.2254 23.3703 19.7471 23.8487C19.2687 24.327 19 24.9758 19 25.6523Z" fill="#EEC9FF" stroke="#8A8A8A" strokeWidth="0.982456"/>
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="13.5" y="6.5" width="21" height="35" rx="1.5" fill="#EFEFEF" stroke="black"/>
          <rect x="21" y="8" width="6" height="2" rx="1" fill="black"/>
          <path d="M24.1014 25.6523C24.1014 24.9758 24.3702 24.327 24.8485 23.8487C25.3269 23.3703 25.9757 23.1016 26.6522 23.1016C27.3287 23.1016 27.9774 23.3703 28.4558 23.8487C28.9342 24.327 29.2029 24.9758 29.2029 25.6523C29.2029 26.3288 28.9342 26.9776 28.4558 27.4559C27.9774 27.9343 27.3287 28.203 26.6522 28.203C25.9757 28.203 25.3269 27.9343 24.8485 27.4559C24.3702 26.9776 24.1014 26.3288 24.1014 25.6523V25.6523Z" stroke="#1E1E1E" strokeWidth="0.982456"/>
          <path d="M19 30.7536C19 30.0771 19.2687 29.4283 19.7471 28.95C20.2254 28.4716 20.8742 28.2029 21.5507 28.2029H24.1014V30.7536C24.1014 31.4301 23.8327 32.0789 23.3544 32.5572C22.876 33.0356 22.2272 33.3043 21.5507 33.3043C20.8742 33.3043 20.2254 33.0356 19.7471 32.5572C19.2687 32.0789 19 31.4301 19 30.7536Z" fill="#B1FFE3" stroke="#1E1E1E" strokeWidth="0.982456"/>
          <path d="M24.1014 18V23.1014H26.6522C27.3287 23.1014 27.9774 22.8327 28.4558 22.3544C28.9342 21.876 29.2029 21.2272 29.2029 20.5507C29.2029 19.8742 28.9342 19.2254 28.4558 18.7471C27.9774 18.2687 27.3287 18 26.6522 18H24.1014Z" fill="#C176E6" stroke="#1E1E1E" strokeWidth="0.982456"/>
          <path d="M19 20.5507C19 21.2272 19.2687 21.876 19.7471 22.3544C20.2254 22.8327 20.8742 23.1014 21.5507 23.1014H24.1014V18H21.5507C20.8742 18 20.2254 18.2687 19.7471 18.7471C19.2687 19.2254 19 19.8742 19 20.5507Z" fill="#F98B78" stroke="#1E1E1E" strokeWidth="0.982456"/>
          <path d="M19 25.6523C19 26.3288 19.2687 26.9776 19.7471 27.4559C20.2254 27.9343 20.8742 28.203 21.5507 28.203H24.1014V23.1016H21.5507C20.8742 23.1016 20.2254 23.3703 19.7471 23.8487C19.2687 24.327 19 24.9758 19 25.6523Z" fill="#EEC9FF" stroke="#1E1E1E" strokeWidth="0.982456"/>
        </svg>
      )
    )
  )
}

export default MobileAppsByDesignSvg
