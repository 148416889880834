export const firebaseConfig = {
  apiKey: "AIzaSyD7MXD1LmJ0NMmfxqCk5jJ4t5YLaEVE2XA",
  authDomain: "portfolio-4a524.firebaseapp.com",
  databaseURL: "https://portfolio-4a524-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "portfolio-4a524",
  storageBucket: "portfolio-4a524.appspot.com",
  messagingSenderId: "1025410859783",
  appId: "1:1025410859783:web:f9617d1e1ea07566e13ba2",
  measurementId: "G-T2FVB9R3GL",
};
