import React from "react";
import {ServiceIconAssetProps} from "./ServiceIconAssetProps";
import {ColorTheme} from "../../../constants/ColorTheme";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/MediaQueries";

const FullstackSvg: React.FC<ServiceIconAssetProps> = ({ colorTheme }) => {
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP})

  return(
    isDesktop ? (
      colorTheme === ColorTheme.DARK ?(
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6257 35.4167C15.6257 24.486 24.4867 15.625 35.4173 15.625H64.584C75.5146 15.625 84.3757 24.486 84.3757 35.4167V81.25C84.3757 82.9759 82.9765 84.375 81.2507 84.375H35.4173C24.4867 84.375 15.6257 75.5139 15.6257 64.5833V35.4167Z" fill="#2F2F2F" stroke="#8A8A8A" strokeWidth="2.08333"/>
          <rect width="53.125" height="53.125" transform="translate(23.4385 23.4375)" fill="#2F2F2F"/>
          <path d="M32.293 54.4271C32.293 64.4886 40.0404 72.1354 50.0013 72.1354C59.9622 72.1354 67.7096 64.4886 67.7096 54.4271C67.7096 36.3163 50.0013 27.8646 50.0013 27.8646C50.0013 36.7187 52.2148 50 45.5742 50C41.1471 50 40.0404 45.5729 40.0404 37.8255C40.0404 37.8255 32.293 44.3655 32.293 54.4271Z" fill="#FFB178"/>
          <path d="M32.293 54.4271C32.293 64.4886 40.0404 72.1354 50.0013 72.1354C59.9622 72.1354 67.7096 64.4886 67.7096 54.4271C67.7096 36.3163 50.0013 27.8646 50.0013 27.8646C50.0013 36.7187 52.2148 50 45.5742 50C41.1471 50 40.0404 45.5729 40.0404 37.8255C40.0404 37.8255 32.293 44.3655 32.293 54.4271Z" stroke="#8A8A8A" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.6252 35.4167C15.6252 24.486 24.4862 15.625 35.4168 15.625H64.5835C75.5141 15.625 84.3752 24.486 84.3752 35.4167V81.25C84.3752 82.9759 82.9761 84.375 81.2502 84.375H35.4168C24.4862 84.375 15.6252 75.514 15.6252 64.5833V35.4167Z" fill="#EFEFEF" stroke="black" strokeWidth="2.08333"/>
          <path d="M32.2915 54.4271C32.2915 64.4887 40.0389 72.1354 49.9998 72.1354C59.9608 72.1354 67.7082 64.4887 67.7082 54.4271C67.7082 36.3163 49.9998 27.8646 49.9998 27.8646C49.9998 36.7188 52.2134 50 45.5728 50C41.1457 50 40.0389 45.5729 40.0389 37.8255C40.0389 37.8255 32.2915 44.3655 32.2915 54.4271Z" fill="#FFB178"/>
          <path d="M32.2915 54.4271C32.2915 64.4886 40.0389 72.1354 49.9998 72.1354C59.9608 72.1354 67.7082 64.4886 67.7082 54.4271C67.7082 36.3163 49.9998 27.8646 49.9998 27.8646C49.9998 36.7188 52.2134 50 45.5728 50C41.1457 50 40.0389 45.5729 40.0389 37.8255C40.0389 37.8255 32.2915 44.3655 32.2915 54.4271Z" stroke="black" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    ) : (
      colorTheme === ColorTheme.DARK ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 17C7.5 11.7533 11.7533 7.5 17 7.5H31C36.2467 7.5 40.5 11.7533 40.5 17V39C40.5 39.8284 39.8284 40.5 39 40.5H17C11.7533 40.5 7.5 36.2467 7.5 31V17Z" fill="#2F2F2F" stroke="#8A8A8A"/>
          <rect width="25.5" height="25.5" transform="translate(11.25 11.25)" fill="#2F2F2F"/>
          <path d="M15.5 26.125C15.5 30.9545 19.2188 34.625 24 34.625C28.7812 34.625 32.5 30.9545 32.5 26.125C32.5 17.4318 24 13.375 24 13.375C24 17.625 25.0625 24 21.875 24C19.75 24 19.2188 21.875 19.2188 18.1562C19.2188 18.1562 15.5 21.2955 15.5 26.125Z" fill="#FFB178"/>
          <path d="M15.5 26.125C15.5 30.9545 19.2188 34.625 24 34.625C28.7812 34.625 32.5 30.9545 32.5 26.125C32.5 17.4318 24 13.375 24 13.375C24 17.625 25.0625 24 21.875 24C19.75 24 19.2188 21.875 19.2188 18.1562C19.2188 18.1562 15.5 21.2955 15.5 26.125Z" stroke="#8A8A8A" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.5 17C7.5 11.7533 11.7533 7.5 17 7.5H31C36.2467 7.5 40.5 11.7533 40.5 17V39C40.5 39.8284 39.8284 40.5 39 40.5H17C11.7533 40.5 7.5 36.2467 7.5 31V17Z" fill="#EFEFEF" stroke="black"/>
          <path d="M15.5 26.125C15.5 30.9545 19.2188 34.625 24 34.625C28.7812 34.625 32.5 30.9545 32.5 26.125C32.5 17.4318 24 13.375 24 13.375C24 17.625 25.0625 24 21.875 24C19.75 24 19.2188 21.875 19.2188 18.1562C19.2188 18.1562 15.5 21.2955 15.5 26.125Z" fill="#FFB178"/>
          <path d="M15.5 26.125C15.5 30.9545 19.2188 34.625 24 34.625C28.7812 34.625 32.5 30.9545 32.5 26.125C32.5 17.4318 24 13.375 24 13.375C24 17.625 25.0625 24 21.875 24C19.75 24 19.2188 21.875 19.2188 18.1562C19.2188 18.1562 15.5 21.2955 15.5 26.125Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    )
  )
}

export default FullstackSvg
