import React from "react";
import {ServiceIconAssetProps} from "./ServiceIconAssetProps";
import {ColorTheme} from "../../../constants/ColorTheme";
import {useMediaQuery} from "react-responsive";
import {MediaQueries} from "../../../constants/MediaQueries";

const BackendSvg: React.FC<ServiceIconAssetProps> = ({ colorTheme }) => {
  const isDesktop = useMediaQuery({ query: MediaQueries.DESKTOP})

  return(
    isDesktop ? (
      colorTheme === ColorTheme.DARK ? (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="15.6257" y="15.625" width="68.75" height="68.75" rx="3.125" fill="#2F2F2F" stroke="#8A8A8A" strokeWidth="2.08333"/>
          <rect width="50" height="50" transform="translate(25.001 25)" fill="#2F2F2F"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M39.9031 29.3677C41.7076 28.3259 44.0042 28.7772 45.2803 30.4244L46.2222 31.6402C47.1115 32.7881 48.5486 33.3334 50.0006 33.3334C51.4528 33.3334 52.8901 32.7881 53.7795 31.6401L54.721 30.4247C55.9971 28.7776 58.2937 28.3263 60.0982 29.3681L62.8197 30.9394C64.6242 31.9812 65.3817 34.1958 64.5932 36.1245L64.0115 37.5475C63.4618 38.8922 63.7056 40.4026 64.4336 41.6598C65.161 42.9159 66.354 43.8967 67.7922 44.0928L69.3136 44.3003C71.3781 44.5818 72.9173 46.3451 72.9173 48.4288V51.5713C72.9173 53.655 71.3781 55.4183 69.3136 55.6998L67.7921 55.9073C66.3539 56.1034 65.161 57.0842 64.4336 58.3403C63.7056 59.5974 63.4618 61.1077 64.0116 62.4524L64.5933 63.8754C65.3817 65.8041 64.6242 68.0187 62.8198 69.0605L60.0982 70.6318C58.2938 71.6736 55.9971 71.2223 54.721 69.5752L53.7797 68.3601C52.8903 67.212 51.4529 66.6667 50.0006 66.6667C48.5485 66.6667 47.1113 67.212 46.222 68.3599L45.2803 69.5755C44.0042 71.2227 41.7075 71.674 39.9031 70.6322L37.1815 69.0609C35.377 68.0191 34.6196 65.8045 35.408 63.8758L35.9898 62.4526C36.5395 61.1079 36.2958 59.5975 35.5677 58.3403C34.8403 57.0842 33.6473 56.1034 32.2091 55.9073L30.6877 55.6998C28.6232 55.4183 27.084 53.655 27.084 51.5713V48.4288C27.084 46.3451 28.6232 44.5818 30.6877 44.3003L32.209 44.0928C33.6473 43.8967 34.8403 42.9158 35.5677 41.6597C36.2958 40.4025 36.5396 38.8921 35.9898 37.5473L35.4081 36.1241C34.6196 34.1954 35.3771 31.9808 37.1816 30.939L39.9031 29.3677ZM50.0007 56.25C53.4524 56.25 56.2507 53.4517 56.2507 50C56.2507 46.5482 53.4524 43.75 50.0007 43.75C46.5489 43.75 43.7507 46.5482 43.7507 50C43.7507 53.4517 46.5489 56.25 50.0007 56.25Z" fill="#81C7F8"/>
          <path d="M48.862 33.3717C48.1163 33.422 47.3778 33.1318 46.9201 32.5411L44.1666 28.9868C43.5286 28.1632 42.3802 27.9375 41.478 28.4585L35.6067 31.8483C34.7044 32.3692 34.3257 33.4765 34.7199 34.4408L36.4208 38.6014C36.7041 39.2945 36.585 40.0807 36.1668 40.7017C35.7442 41.3291 35.3633 41.9868 35.0278 42.6709C34.6984 43.3425 34.0776 43.838 33.3363 43.9391L28.8858 44.546C27.8536 44.6868 27.084 45.5684 27.084 46.6102V53.3898C27.084 54.4317 27.8536 55.3133 28.8858 55.4541L33.3363 56.061C34.0776 56.162 34.6984 56.6575 35.0278 57.3292C35.3633 58.0132 35.7442 58.6709 36.1667 59.2982C36.585 59.9193 36.7041 60.7054 36.4207 61.3985L34.7199 65.5591C34.3257 66.5234 34.7044 67.6307 35.6066 68.1517L41.478 71.5415C42.3802 72.0624 43.5285 71.8367 44.1666 71.0131L46.92 67.459C47.3777 66.8682 48.1162 66.5781 48.8619 66.6284C49.2382 66.6538 49.6179 66.6667 50.0006 66.6667C50.3835 66.6667 50.7633 66.6538 51.1397 66.6284C51.8853 66.5781 52.6239 66.8682 53.0816 67.459L55.8347 71.0128C56.4728 71.8363 57.6211 72.062 58.5233 71.5411L64.3947 68.1513C65.2969 67.6304 65.6756 66.5231 65.2814 65.5587L63.5807 61.3984C63.2973 60.7053 63.4164 59.9192 63.8347 59.2981C64.2571 58.6708 64.638 58.0132 64.9735 57.3292C65.3029 56.6576 65.9237 56.162 66.665 56.061L71.1155 55.4541C72.1477 55.3133 72.9173 54.4317 72.9173 53.3899V46.6102C72.9173 45.5684 72.1477 44.6868 71.1155 44.546L66.665 43.9391C65.9237 43.838 65.3029 43.3425 64.9735 42.6708C64.638 41.9869 64.2571 41.3292 63.8346 40.7019C63.4164 40.0808 63.2973 39.2947 63.5806 38.6016L65.2814 34.4412C65.6756 33.4768 65.2969 32.3695 64.3946 31.8486L58.5233 28.4588C57.6211 27.9379 56.4727 28.1636 55.8347 28.9872L53.0815 32.5411C52.6238 33.1319 51.8852 33.422 51.1395 33.3717C50.7632 33.3463 50.3834 33.3334 50.0006 33.3334C49.6179 33.3334 49.2383 33.3463 48.862 33.3717Z" stroke="#8A8A8A" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M56.2506 50C56.2506 53.4517 53.4524 56.25 50.0007 56.25C46.5489 56.25 43.7507 53.4517 43.7507 50C43.7507 46.5482 46.5489 43.75 50.0007 43.75C53.4524 43.75 56.2506 46.5482 56.2506 50Z" stroke="#8A8A8A" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="15.6252" y="15.625" width="68.75" height="68.75" rx="3.125" fill="#EFEFEF" stroke="black" strokeWidth="2.08333"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M39.9026 29.3677C41.7071 28.3259 44.0038 28.7772 45.2798 30.4244L46.2218 31.6403C47.111 32.7881 48.5481 33.3334 50.0002 33.3334C51.4523 33.3334 52.8896 32.7881 53.779 31.6401L54.7205 30.4248C55.9966 28.7776 58.2932 28.3263 60.0977 29.3681L62.8192 30.9394C64.6237 31.9812 65.3812 34.1958 64.5928 36.1245L64.011 37.5475C63.4613 38.8923 63.7051 40.4026 64.4331 41.6598C65.1605 42.9159 66.3535 43.8967 67.7917 44.0929L69.3131 44.3003C71.3777 44.5819 72.9168 46.3452 72.9168 48.4288V51.5714C72.9168 53.655 71.3777 55.4183 69.3131 55.6998L67.7916 55.9073C66.3535 56.1034 65.1605 57.0842 64.4331 58.3403C63.7051 59.5974 63.4613 61.1077 64.0111 62.4524L64.5928 63.8755C65.3812 65.8041 64.6238 68.0188 62.8193 69.0606L60.0977 70.6319C58.2933 71.6737 55.9966 71.2224 54.7205 69.5752L53.7792 68.3601C52.8898 67.2121 51.4524 66.6667 50.0002 66.6667C48.548 66.6667 47.1108 67.212 46.2215 68.3599L45.2798 69.5756C44.0037 71.2227 41.7071 71.674 39.9026 70.6322L37.181 69.0609C35.3766 68.0191 34.6191 65.8045 35.4075 63.8758L35.9893 62.4527C36.5391 61.1079 36.2953 59.5975 35.5672 58.3403C34.8398 57.0843 33.6468 56.1034 32.2086 55.9073L30.6872 55.6998C28.6227 55.4183 27.0835 53.655 27.0835 51.5714V48.4288C27.0835 46.3452 28.6227 44.5819 30.6872 44.3003L32.2086 44.0929C33.6468 43.8967 34.8398 42.9159 35.5672 41.6597C36.2953 40.4025 36.5391 38.8921 35.9894 37.5473L35.4076 36.1242C34.6191 34.1955 35.3766 31.9808 37.1811 30.939L39.9026 29.3677ZM50.0002 56.25C53.4519 56.25 56.2502 53.4518 56.2502 50C56.2502 46.5482 53.4519 43.75 50.0002 43.75C46.5484 43.75 43.7502 46.5482 43.7502 50C43.7502 53.4518 46.5484 56.25 50.0002 56.25Z" fill="#81C7F8"/>
          <path d="M48.8615 33.3717C48.1159 33.422 47.3773 33.1319 46.9196 32.5411L44.1661 28.9868C43.5281 28.1632 42.3797 27.9376 41.4775 28.4585L35.6062 31.8483C34.704 32.3692 34.3252 33.4765 34.7194 34.4409L36.4203 38.6015C36.7036 39.2946 36.5845 40.0807 36.1663 40.7018C35.7438 41.3292 35.3628 41.9868 35.0273 42.6709C34.6979 43.3426 34.0771 43.8381 33.3358 43.9391L28.8853 44.546C27.8531 44.6868 27.0835 45.5684 27.0835 46.6103V53.3899C27.0835 54.4317 27.8531 55.3133 28.8853 55.4541L33.3358 56.061C34.0771 56.1621 34.6979 56.6576 35.0273 57.3293C35.3628 58.0133 35.7437 58.6709 36.1662 59.2983C36.5845 59.9193 36.7036 60.7055 36.4202 61.3986L34.7194 65.5591C34.3252 66.5235 34.7039 67.6308 35.6061 68.1517L41.4775 71.5415C42.3797 72.0624 43.528 71.8367 44.1661 71.0132L46.9195 67.459C47.3772 66.8682 48.1157 66.5781 48.8614 66.6284C49.2377 66.6538 49.6174 66.6667 50.0002 66.6667C50.383 66.6667 50.7628 66.6538 51.1392 66.6284C51.8848 66.5781 52.6234 66.8682 53.0811 67.459L55.8343 71.0128C56.4723 71.8364 57.6206 72.062 58.5229 71.5411L64.3942 68.1513C65.2964 67.6304 65.6752 66.5231 65.2809 65.5588L63.5802 61.3984C63.2968 60.7053 63.4159 59.9192 63.8342 59.2981C64.2566 58.6708 64.6376 58.0132 64.973 57.3293C65.3024 56.6576 65.9232 56.1621 66.6645 56.061L71.115 55.4541C72.1472 55.3133 72.9168 54.4317 72.9168 53.3899V46.6103C72.9168 45.5684 72.1472 44.6868 71.115 44.546L66.6645 43.9391C65.9232 43.8381 65.3024 43.3426 64.973 42.6709C64.6376 41.9869 64.2566 41.3292 63.8341 40.7019C63.4159 40.0808 63.2968 39.2947 63.5801 38.6016L65.2809 34.4412C65.6751 33.4769 65.2964 32.3696 64.3941 31.8487L58.5228 28.4589C57.6206 27.9379 56.4722 28.1636 55.8342 28.9872L53.081 32.5411C52.6233 33.1319 51.8847 33.422 51.139 33.3717C50.7627 33.3463 50.3829 33.3334 50.0002 33.3334C49.6175 33.3334 49.2378 33.3463 48.8615 33.3717Z" stroke="black" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M56.2502 50C56.2502 53.4518 53.4519 56.25 50.0002 56.25C46.5484 56.25 43.7502 53.4518 43.7502 50C43.7502 46.5482 46.5484 43.75 50.0002 43.75C53.4519 43.75 56.2502 46.5482 56.2502 50Z" stroke="black" strokeWidth="2.08333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    ) : (
      colorTheme === ColorTheme.DARK ? (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="7.5" y="7.5" width="33" height="33" rx="1.5" fill="#2F2F2F" stroke="#8A8A8A"/>
          <rect width="24" height="24" transform="translate(12 12)" fill="#2F2F2F"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M19.1532 14.0965C20.0193 13.5965 21.1217 13.8131 21.7342 14.6037L22.1864 15.1873C22.6132 15.7383 23.303 16 24 16C24.697 16 25.3869 15.7383 25.8138 15.1873L26.2658 14.6039C26.8783 13.8133 27.9807 13.5966 28.8468 14.0967L30.1532 14.8509C31.0193 15.351 31.3829 16.414 31.0044 17.3398L30.7252 18.0228C30.4613 18.6683 30.5784 19.3933 30.9278 19.9967C31.277 20.5996 31.8496 21.0704 32.5399 21.1646L33.2702 21.2642C34.2612 21.3993 35 22.2457 35 23.2458V24.7543C35 25.7544 34.2612 26.6008 33.2702 26.7359L32.5399 26.8355C31.8496 26.9296 31.277 27.4004 30.9278 28.0033C30.5784 28.6068 30.4614 29.3317 30.7252 29.9772L31.0045 30.6602C31.3829 31.586 31.0193 32.649 30.1532 33.1491L28.8468 33.9033C27.9807 34.4034 26.8783 34.1867 26.2658 33.3961L25.8139 32.8129C25.387 32.2618 24.6971 32 24 32C23.303 32 22.6131 32.2618 22.1863 32.8128L21.7342 33.3963C21.1217 34.1869 20.0193 34.4035 19.1532 33.9035L17.8468 33.1493C16.9807 32.6492 16.6171 31.5862 16.9955 30.6604L17.2748 29.9773C17.5387 29.3318 17.4217 28.6068 17.0722 28.0034C16.723 27.4004 16.1504 26.9296 15.4601 26.8355L14.7298 26.7359C13.7388 26.6008 13 25.7544 13 24.7543V23.2458C13 22.2457 13.7388 21.3993 14.7298 21.2642L15.46 21.1646C16.1504 21.0704 16.723 20.5996 17.0722 19.9967C17.4217 19.3932 17.5387 18.6682 17.2748 18.0227L16.9956 17.3396C16.6171 16.4138 16.9807 15.3508 17.8468 14.8507L19.1532 14.0965ZM24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27Z" fill="#81C7F8"/>
          <path d="M23.4534 16.0184C23.0955 16.0426 22.741 15.9033 22.5213 15.6197L21.1997 13.9137C20.8934 13.5184 20.3422 13.41 19.9091 13.6601L17.0909 15.2872C16.6578 15.5372 16.476 16.0687 16.6653 16.5316L17.4817 18.5287C17.6177 18.8614 17.5605 19.2387 17.3597 19.5369C17.1569 19.838 16.9741 20.1537 16.813 20.482C16.6549 20.8044 16.3569 21.0423 16.0011 21.0908L13.8649 21.3821C13.3694 21.4497 13 21.8729 13 22.3729V25.6271C13 26.1272 13.3694 26.5504 13.8649 26.618L16.0011 26.9093C16.3569 26.9578 16.6549 27.1956 16.813 27.518C16.9741 27.8464 17.1569 28.162 17.3597 28.4632C17.5605 28.7613 17.6176 29.1386 17.4816 29.4713L16.6652 31.4684C16.476 31.9313 16.6578 32.4628 17.0909 32.7128L19.9091 34.3399C20.3422 34.59 20.8934 34.4816 21.1996 34.0863L22.5213 32.3803C22.741 32.0968 23.0955 31.9575 23.4534 31.9817C23.634 31.9938 23.8163 32 24 32C24.1838 32 24.3661 31.9938 24.5467 31.9816C24.9046 31.9575 25.2592 32.0967 25.4789 32.3803L26.8004 34.0861C27.1066 34.4815 27.6578 34.5898 28.0909 34.3397L30.9091 32.7126C31.3422 32.4626 31.524 31.9311 31.3348 31.4682L30.5184 29.4712C30.3824 29.1386 30.4396 28.7612 30.6403 28.4631C30.8431 28.162 31.026 27.8464 31.187 27.5181C31.3451 27.1956 31.6431 26.9578 31.9989 26.9093L34.1351 26.618C34.6306 26.5504 35 26.1272 35 25.6271V22.3729C35 21.8729 34.6306 21.4497 34.1351 21.3821L31.9989 21.0908C31.6431 21.0423 31.3451 20.8044 31.187 20.482C31.0259 20.1537 30.8431 19.838 30.6403 19.5369C30.4395 19.2388 30.3824 18.8615 30.5184 18.5288L31.3347 16.5318C31.524 16.0689 31.3422 15.5374 30.9091 15.2874L28.0909 13.6603C27.6578 13.4102 27.1066 13.5185 26.8003 13.9139L25.4788 15.6197C25.2591 15.9033 24.9046 16.0426 24.5467 16.0184C24.366 16.0062 24.1837 16 24 16C23.8163 16 23.6341 16.0062 23.4534 16.0184Z" stroke="#8A8A8A" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M27 24C27 25.6569 25.6569 27 24 27C22.3431 27 21 25.6569 21 24C21 22.3431 22.3431 21 24 21C25.6569 21 27 22.3431 27 24Z" stroke="#8A8A8A" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="7.5" y="7.5" width="33" height="33" rx="1.5" fill="#EFEFEF" stroke="black"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M19.1532 14.0965C20.0193 13.5965 21.1217 13.8131 21.7342 14.6037L22.1864 15.1873C22.6132 15.7383 23.303 16 24 16C24.697 16 25.3869 15.7383 25.8138 15.1873L26.2658 14.6039C26.8783 13.8133 27.9807 13.5966 28.8468 14.0967L30.1532 14.8509C31.0193 15.351 31.3829 16.414 31.0044 17.3398L30.7252 18.0228C30.4613 18.6683 30.5784 19.3933 30.9278 19.9967C31.277 20.5996 31.8496 21.0704 32.5399 21.1646L33.2702 21.2642C34.2612 21.3993 35 22.2457 35 23.2458V24.7543C35 25.7544 34.2612 26.6008 33.2702 26.7359L32.5399 26.8355C31.8496 26.9296 31.277 27.4004 30.9278 28.0033C30.5784 28.6068 30.4614 29.3317 30.7252 29.9772L31.0045 30.6602C31.3829 31.586 31.0193 32.649 30.1532 33.1491L28.8468 33.9033C27.9807 34.4034 26.8783 34.1867 26.2658 33.3961L25.8139 32.8129C25.387 32.2618 24.6971 32 24 32C23.303 32 22.6131 32.2618 22.1863 32.8128L21.7342 33.3963C21.1217 34.1869 20.0193 34.4035 19.1532 33.9035L17.8468 33.1493C16.9807 32.6492 16.6171 31.5862 16.9955 30.6604L17.2748 29.9773C17.5387 29.3318 17.4217 28.6068 17.0722 28.0034C16.723 27.4004 16.1504 26.9296 15.4601 26.8355L14.7298 26.7359C13.7388 26.6008 13 25.7544 13 24.7543V23.2458C13 22.2457 13.7388 21.3993 14.7298 21.2642L15.46 21.1646C16.1504 21.0704 16.723 20.5996 17.0722 19.9967C17.4217 19.3932 17.5387 18.6682 17.2748 18.0227L16.9956 17.3396C16.6171 16.4138 16.9807 15.3508 17.8468 14.8507L19.1532 14.0965ZM24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27Z" fill="#81C7F8"/>
          <path d="M23.4534 16.0184C23.0955 16.0426 22.741 15.9033 22.5213 15.6197L21.1997 13.9137C20.8934 13.5184 20.3422 13.41 19.9091 13.6601L17.0909 15.2872C16.6578 15.5372 16.476 16.0687 16.6653 16.5316L17.4817 18.5287C17.6177 18.8614 17.5605 19.2387 17.3597 19.5369C17.1569 19.838 16.9741 20.1537 16.813 20.482C16.6549 20.8044 16.3569 21.0423 16.0011 21.0908L13.8649 21.3821C13.3694 21.4497 13 21.8729 13 22.3729V25.6271C13 26.1272 13.3694 26.5504 13.8649 26.618L16.0011 26.9093C16.3569 26.9578 16.6549 27.1956 16.813 27.518C16.9741 27.8464 17.1569 28.162 17.3597 28.4632C17.5605 28.7613 17.6176 29.1386 17.4816 29.4713L16.6652 31.4684C16.476 31.9313 16.6578 32.4628 17.0909 32.7128L19.9091 34.3399C20.3422 34.59 20.8934 34.4816 21.1996 34.0863L22.5213 32.3803C22.741 32.0968 23.0955 31.9575 23.4534 31.9817C23.634 31.9938 23.8163 32 24 32C24.1838 32 24.3661 31.9938 24.5467 31.9816C24.9046 31.9575 25.2592 32.0967 25.4789 32.3803L26.8004 34.0861C27.1066 34.4815 27.6578 34.5898 28.0909 34.3397L30.9091 32.7126C31.3422 32.4626 31.524 31.9311 31.3348 31.4682L30.5184 29.4712C30.3824 29.1386 30.4396 28.7612 30.6403 28.4631C30.8431 28.162 31.026 27.8464 31.187 27.5181C31.3451 27.1956 31.6431 26.9578 31.9989 26.9093L34.1351 26.618C34.6306 26.5504 35 26.1272 35 25.6271V22.3729C35 21.8729 34.6306 21.4497 34.1351 21.3821L31.9989 21.0908C31.6431 21.0423 31.3451 20.8044 31.187 20.482C31.0259 20.1537 30.8431 19.838 30.6403 19.5369C30.4395 19.2388 30.3824 18.8615 30.5184 18.5288L31.3347 16.5318C31.524 16.0689 31.3422 15.5374 30.9091 15.2874L28.0909 13.6603C27.6578 13.4102 27.1066 13.5185 26.8003 13.9139L25.4788 15.6197C25.2591 15.9033 24.9046 16.0426 24.5467 16.0184C24.366 16.0062 24.1837 16 24 16C23.8163 16 23.6341 16.0062 23.4534 16.0184Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M27 24C27 25.6569 25.6569 27 24 27C22.3431 27 21 25.6569 21 24C21 22.3431 22.3431 21 24 21C25.6569 21 27 22.3431 27 24Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    )
  )
}

export default BackendSvg
